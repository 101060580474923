import React, { useRef } from "react";
import { style } from "typestyle";
import { Router } from "../../routing/router";
import { routes } from "../../routing/routes";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    mobileContainer: style({
        display: "none",
        width: "100%",
        backgroundColor: Colors.darkGreen,
        color: "white",
        $nest: {
            [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
                display: "block"
            }
        }
    }),
    innerContainer: style({
        margin: "0 auto",
        height: "100%",
        maxWidth: Dimensions.maxUIWidth,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: `${Dimensions.padding * 4}px ${Dimensions.padding * 4}px`
    }),
    blockWrapper: style({
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
        textAlign: "center",
        marginTop: Dimensions.padding * 2
    }),
    blockText: style({
        display: "flex",
        flexDirection: "column"
    }),
    textLine: style({
        fontWeight: 300,
        lineHeight: "170%"
    }),
    locationLogo: style({
        backgroundImage: `url('/svgs/location.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: 20,
        marginBottom: 10,
        marginRight: 15,
        lineHeight: "170%"
    }),
    phoneLogo: style({
        backgroundImage: `url('/svgs/phone.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: 20,
        marginBottom: 10,
        marginTop: 4,
        marginRight: 15,
        lineHeight: "170%"
    }),
    linkedInLogo: style({
        backgroundImage: `url('/svgs/linkedin.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: 20,
        height: 20,
        cursor: "pointer"
    }),
    mailLogo: style({
        backgroundImage: `url('/svgs/mail.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: 20,
        height: 20,
        marginLeft: Dimensions.padding,
        cursor: "pointer"
    }),
    phone: style({ textDecoration: "none", color: Colors.white }),
    logo: style({
        backgroundImage: `url('/svgs/logolw-grijs.svg')`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: 35
    }),
    navItems: style({
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        fontWeight: "bold",
        padding: `${Dimensions.padding}px 0`
    }),
    navItem: style({
        fontFamily: "Open Sans",
        fontWeight: 600,
        letterSpacing: "1px",
        fontSize: 11,
        cursor: "pointer",
        userSelect: "none",
        display: "inline-block",
        textTransform: "uppercase",
        $nest: {
            "&::before": { display: "block", height: 10, content: "''" },
            "&::after": {
                height: 10,
                display: "block",
                transform: "scaleX(0)",
                content: "''",
                borderBottom: `1px solid ${Colors.orange}`,
                transition: "transform 250ms ease-in-out"
            },
            "&:hover": {
                color: Colors.orange,
                $nest: {
                    "&::after": {
                        transform: "scaleX(1)"
                    }
                }
            }
        }
    }),
    navItemActive: style({
        color: Colors.orange,
        $nest: {
            "&::after": {
                height: 10,
                display: "block",
                content: "''",
                borderBottom: `1px solid ${Colors.orange}`,
                width: "100%",
                transition: "none",
                transform: "scaleX(1)"
            }
        }
    }),
    line: style({
        width: "100%",
        height: 1,
        border: "1px solid white",
        marginTop: Dimensions.padding * 2
    }),
    copyRight: style({
        textAlign: "center",
        fontWeight: 300
    })
};
export const FooterMobile = (props) => {
    const isNavItemActive = useRef((forRoutePath = "home") => {
        let navActive = false;
        if (forRoutePath === "home")
            navActive = window.location.pathname === "/";
        else
            navActive = window.location.pathname.includes(forRoutePath);
        return navActive;
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames.mobileContainer },
            React.createElement("div", { className: classNames.innerContainer },
                React.createElement("div", { className: classNames.logo }),
                React.createElement("div", { className: classNames.line }),
                React.createElement("div", { className: classNames.navItems },
                    React.createElement("div", { className: [
                            classNames.navItem,
                            isNavItemActive.current() ? classNames.navItemActive : ""
                        ]
                            .filter(Boolean)
                            .join(" "), onClick: () => Router.instance.navigate(routes.home.path) }, "Home"),
                    React.createElement("div", { className: [
                            classNames.navItem,
                            isNavItemActive.current(routes.design.path)
                                ? classNames.navItemActive
                                : ""
                        ]
                            .filter(Boolean)
                            .join(" "), onClick: () => Router.instance.navigate(routes.design.path) }, "Design")),
                React.createElement("div", { className: classNames.blockWrapper },
                    React.createElement("div", { className: classNames.phoneLogo }),
                    React.createElement("div", { className: classNames.blockText },
                        React.createElement("div", { className: classNames.textLine },
                            React.createElement("a", { className: classNames.phone, href: "tel:+31 6 14 16 84 23" }, "+31 6 14 16 84 23")))),
                React.createElement("div", { className: classNames.line, style: { marginBottom: Dimensions.padding * 2 } }),
                React.createElement("div", { className: classNames.copyRight },
                    "\u00A9 ",
                    new Date().getFullYear(),
                    " LivingWindow")))));
};
