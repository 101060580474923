import React, { useEffect, useRef, useState } from "react";
import { style } from "typestyle";
import { Router } from "../../routing/router";
import { routes } from "../../routing/routes";
import MobileMenu from "../../statics/MobileMenu";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
const activeNavItem = {
    color: Colors.orange,
    borderBottom: `1px solid ${Colors.orange}`
};
const classNames = {
    container: style({
        position: "fixed",
        top: 0,
        width: "100%",
        height: Dimensions.navBarHeight,
        backgroundColor: Colors.white,
        $debugName: "container",
        zIndex: 9
    }),
    boxShadow: style({
        boxShadow: "0 2px 4px 0px rgba(0,0,0,.5)"
    }),
    innerContainer: style({
        display: "flex",
        height: "100%",
        margin: "0 auto",
        alignItems: "center",
        maxWidth: Dimensions.maxUIWidth,
        $debugName: "inner-container",
        padding: `0 ${Dimensions.padding * 2}px`
    }),
    left: style({
        marginRight: "auto"
    }),
    right: style({
        marginLeft: "auto",
        $nest: {
            "@media screen and (max-width: 900px)": {
                display: "none"
            }
        }
    }),
    hamburgerMenu: style({
        display: "none",
        backgroundImage: `url('/svgs/menu.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        pointerEvents: "none",
        width: 40,
        height: "100%",
        marginLeft: "auto",
        userSelect: "none",
        $nest: {
            "@media (max-width: 900px)": {
                display: "block",
                pointerEvents: "auto",
                cursor: "pointer"
            }
        }
    }),
    navItem: style({
        fontFamily: "Open Sans",
        fontWeight: 600,
        letterSpacing: "1px",
        fontSize: 12,
        cursor: "pointer",
        userSelect: "none",
        marginLeft: "65px",
        display: "inline-block",
        textTransform: "uppercase",
        $nest: {
            "&::before": { display: "block", height: 10, content: "''" },
            "&::after": {
                height: 10,
                display: "block",
                transform: "scaleX(0)",
                content: "''",
                borderBottom: `2px solid ${Colors.orange}`,
                transition: "transform 250ms ease-in-out"
            },
            "&:hover": {
                color: Colors.orange,
                $nest: {
                    "&::after": {
                        transform: "scaleX(1)"
                    }
                }
            }
        }
    }),
    navItemActive: style({
        color: Colors.orange,
        $nest: {
            "&::after": {
                height: 10,
                display: "block",
                content: "''",
                borderBottom: `2px solid ${Colors.orange}`,
                width: "100%",
                transition: "none",
                transform: "scaleX(1)"
            }
        }
    }),
    logo: style({
        backgroundImage: `url("/svgs/logolw-grijs.svg")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        width: 180,
        height: 70,
        cursor: "pointer"
    })
};
export const NavigationBar = (props) => {
    const [isScrolled, setIsScrolled] = useState(null);
    useEffect(() => {
        const onScroll = () => {
            if (window.pageYOffset > 0)
                setIsScrolled(true);
            else
                setIsScrolled(false);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [isScrolled]);
    const containerClassNames = [
        classNames.container,
        isScrolled && classNames.boxShadow
    ]
        .filter(Boolean)
        .join(" ");
    const isNavItemActive = useRef((forRoutePath = "home") => {
        if (forRoutePath === "home")
            return window.location.pathname === "/";
        return window.location.pathname.includes(forRoutePath);
    });
    return (React.createElement("div", { className: containerClassNames },
        React.createElement("div", { className: classNames.innerContainer },
            React.createElement("div", { className: classNames.left },
                React.createElement("div", { className: classNames.logo, onClick: () => Router.instance.navigate(routes.home.path) })),
            React.createElement("div", { className: classNames.hamburgerMenu, onClick: () => {
                    MobileMenu.show();
                } }),
            React.createElement("div", { className: classNames.right },
                React.createElement("div", { onClick: () => Router.instance.navigate(routes.home.path), className: [
                        classNames.navItem,
                        isNavItemActive.current() ? classNames.navItemActive : ""
                    ]
                        .filter(Boolean)
                        .join(" ") }, "Home"),
                React.createElement("div", { className: [
                        classNames.navItem,
                        isNavItemActive.current(routes.design.path)
                            ? classNames.navItemActive
                            : ""
                    ]
                        .filter(Boolean)
                        .join(" "), onClick: () => Router.instance.navigate(routes.design.path) }, "Design")))));
};
