import React, { useRef } from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    container: style({
        position: "relative",
        width: "100%",
        overflow: "hidden"
    }),
    innerContainer: style({
        boxSizing: "border-box",
        position: "relative",
        width: "100%",
        maxWidth: Dimensions.maxUIWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 0,
        padding: `${Dimensions.padding * 8}px ${Dimensions.padding * 2}px`,
        $debugName: "inner-container",
        $nest: {
            "@media screen and (max-width: 900px)": {
                padding: `${Dimensions.padding * 4}px ${Dimensions.padding}px`
            }
        }
    }),
    fullWidth: style({
        maxWidth: "none"
    }),
    belowNavBar: style({
        marginTop: Dimensions.navBarHeight,
        paddingTop: 0,
        paddingBottom: 0
    })
};
export const PageInnerContainer = (props) => {
    const innerClassNames = useRef([
        classNames.innerContainer,
        props.className,
        props.isFullWidth === true ? classNames.fullWidth : "",
        props.isTopContent === true ? classNames.belowNavBar : ""
    ].join(" "));
    return (React.createElement("div", { className: classNames.container },
        React.createElement("div", { className: innerClassNames.current }, props.children),
        props.backgroundElement && props.backgroundElement));
};
