import React from "react";
import { style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    header: style({
        color: Colors.orange,
        fontSize: "2rem",
        fontWeight: "bold",
        marginBottom: Dimensions.padding * 2,
        $nest: {
            "@media (max-width: 900px)": {
                fontSize: "1.4rem"
            }
        }
    }),
    subHeader: style({
        fontWeight: 300,
        fontSize: "1.5rem",
        marginBottom: Dimensions.padding * 2
    }),
    center: style({ textAlign: "center" })
};
export const Header = (_props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: [
                classNames.header,
                _props.center ? classNames.center : "",
                _props.className
            ]
                .filter(Boolean)
                .join(" ") }, _props.header),
        React.createElement("div", { className: [
                classNames.subHeader,
                _props.center ? classNames.center : ""
            ]
                .filter(Boolean)
                .join(" ") }, _props.subHeader)));
};
