import { Home } from "../components/views/home.view";
import { Design } from "../components/views/design.view";
export const routes = {
    home: {
        component: Home,
        path: "/",
        exact: true
    },
    design: {
        component: Design,
        path: "/design",
        exact: true
    }
};
