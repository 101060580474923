import React from "react";
import { style } from "typestyle";
import { Colors } from "../../../styling/Colors";
import { Dimensions } from "../../../styling/Dimensions";
const classNames = {
    desktopWrapper: style({
        $debugName: "desktop-wrapper",
        display: "block",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "none"
        }
    }),
    mobileWrapper: style({
        $debugName: "mobile-wrapper",
        display: "none",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "block"
        }
    }),
    firstBlockWrapper: style({
        display: "flex",
        justifyContent: "space-between"
    }),
    secondBlockWrapper: style({
        $debugName: "second-block-wrapper",
        display: "flex",
        justifyContent: "space-between"
        // flexDirection: "column"
    }),
    block: style({
        $debugName: "block",
        width: "45%",
        display: "flex",
        flexDirection: "column"
    }),
    header: style({
        fontSize: "1.5rem",
        fontWeight: "bold",
        marginBottom: Dimensions.padding * 2,
        $nest: {
            "@media (max-width: 900px)": {
                fontSize: "1.4rem"
            }
        }
    }),
    whatWeDoContent: style({
        fontWeight: 300,
        fontSize: "1.5rem",
        marginBottom: 40
    }),
    designImg: style({
        width: "90%"
    }),
    content: style({
        flex: 1,
        fontWeight: 300
    }),
    innerBlock: style({
        display: "flex",
        flexDirection: "column"
    }),
    link: style({
        width: "100%",
        textDecoration: "none",
        textTransform: "uppercase",
        color: Colors.lightOrange,
        cursor: "pointer",
        fontWeight: "bold",
        borderBottom: "1px solid black",
        paddingTop: Dimensions.padding * 4,
        paddingBottom: Dimensions.padding * 1.5
    }),
    howWeWorkWrapper: style({
        backgroundColor: Colors.lightGreen
    }),
    howWeWorkHeader: style({
        fontSize: "2rem",
        fontWeight: "bold",
        textAlign: "center",
        color: "white",
        marginBottom: Dimensions.padding * 4
    }),
    orange: style({
        color: Colors.lightOrange
    }),
    mobileBlock: style({
        marginBottom: Dimensions.padding * 4
    }),
    mobileHeader: style({
        fontSize: "1.125rem",
        fontWeight: "bold",
        marginBottom: Dimensions.padding
    }),
    whatWeDoMobileImage: style({
        display: "block",
        width: "70%",
        maxWidth: 500,
        margin: "0 auto",
        marginBottom: 40
    }),
    mobileExpandableContent: style({
        fontWeight: 300
    }),
    mobileExpandableFirstBlock: style({
        marginBottom: Dimensions.padding * 2
    }),
    mobileExpanbleSecondBlock: style({
        fontStyle: "italic"
    }),
    linkWrapper: style({
        display: "flex",
        marginTop: Dimensions.padding * 2
    }),
    mobileTextWrapper: style({
        fontWeight: 300,
        marginBottom: Dimensions.padding * 2
    })
};
export const DesignContent = (_props) => {
    return (React.createElement("div", { id: "design-content" },
        React.createElement("div", { className: classNames.desktopWrapper },
            React.createElement("div", { className: classNames.firstBlockWrapper },
                React.createElement("div", { className: classNames.block },
                    React.createElement("div", { className: [classNames.header, classNames.orange].join(" ") }, "LivingWindow Design"),
                    React.createElement("div", { className: classNames.whatWeDoContent }, "E\u00E9n beeld zegt meer dan duizend woorden."),
                    React.createElement("img", { src: "/svgs/design/visual.svg", className: classNames.designImg })),
                React.createElement("div", { className: classNames.block },
                    React.createElement("div", { className: classNames.content },
                        "Wij brengen je verhaal, je vraagstuk of je boodschap tot leven in een krachtige visuele uitwerking. Deze visuele samenvatting is een goede basis voor de dialoog in verschillende contexten. Wij zijn analytisch en strategisch en verdiepen ons in de voorgelegde context. Wij zien het belang van de verschillende perspectieven en gaan op zoek naar de kern van de boodschap.",
                        React.createElement("br", null),
                        " We maken een ontwerp dat aansluit bij jouw stijl en doelgroep. Dit ontwerp omzetten naar een pixel perfect eindresultaat - animaties, films, infographics, visuals en werkvormen - kent voor ons geen geheimen."),
                    React.createElement("a", { className: classNames.link, onClick: () => _props.onClickDesignPortfolio() }, "> Bekijk enkele voorbeelden")))),
        React.createElement("div", { className: classNames.mobileWrapper },
            React.createElement("div", { className: classNames.mobileBlock },
                React.createElement("div", { className: [classNames.mobileHeader, classNames.orange].join(" ") }, "LivingWindow Design"),
                React.createElement("div", { className: classNames.whatWeDoContent }, "E\u00E9n beeld zegt meer dan duizend woorden."),
                React.createElement("img", { src: "/svgs/design/visual.svg", className: classNames.whatWeDoMobileImage }),
                React.createElement("div", { className: classNames.mobileTextWrapper },
                    "Wij brengen je verhaal, je vraagstuk of je boodschap tot leven in een krachtige visuele uitwerking. Deze visuele samenvatting is een goede basis voor de dialoog in verschillende contexten. Wij zijn analytisch en strategisch en verdiepen ons in de voorgelegde context. Wij zien het belang van de verschillende perspectieven en gaan op zoek naar de kern van de boodschap.",
                    React.createElement("br", null),
                    " We maken een ontwerp dat aansluit bij jouw stijl en doelgroep. Dit ontwerp omzetten naar een pixel perfect eindresultaat - animaties, films, infographics, visuals en werkvormen - kent voor ons geen geheimen."),
                React.createElement("div", { className: classNames.linkWrapper },
                    React.createElement("a", { className: classNames.link, onClick: () => _props.onClickDesignPortfolio() }, "> Bekijk enkele voorbeelden"))),
            React.createElement("div", { className: classNames.mobileBlock }))));
};
