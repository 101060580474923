var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AssetType } from "living-window-packages/enums/asset-type.enum";
import React, { useEffect, useRef, useState } from "react";
import { style } from "typestyle";
import { portfolioItemService } from "../../../services/portfolio-item.service";
import { vimeoService } from "../../../services/vimeo.service";
import AssetOverlay from "../../../statics/AssetOverlay";
import { Colors } from "../../../styling/Colors";
import { Dimensions } from "../../../styling/Dimensions";
import { Header } from "../header";
import { PortfolioItemGrid } from "../portfolioItemGrid";
const classNames = {
    desktopWrapper: style({
        $debugName: "desktop-wrapper",
        display: "block",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "none"
        }
    }),
    mobileWrapper: style({
        $debugName: "mobile-wrapper",
        display: "none",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "block"
        }
    }),
    smallMobileWrapper: style({
        $debugName: "small-mobile-wrapper",
        display: "none",
        $nest: {
            "@media screen and (max-width: 599px)": {
                display: "block"
            }
        }
    }),
    header: style({
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: Colors.orange,
        textAlign: "center",
        marginBottom: Dimensions.padding * 2
    }),
    smallHeader: style({
        fontSize: "1rem",
        fontWeight: "bold",
        color: Colors.orange,
        textAlign: "center",
        marginBottom: Dimensions.padding * 2
    }),
    subheader: style({
        fontSize: "2rem",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: Dimensions.padding * 2
    }),
    subText: style({
        fontWeight: 300,
        fontSize: "1rem",
        maxWidth: 540,
        margin: "0 auto",
        textAlign: "center",
        marginBottom: Dimensions.padding * 4
    }),
    mobileImageContainer: style({
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap"
    }),
    image: style({
        objectFit: "cover",
        width: "100%",
        height: 480,
        marginBottom: 20
    }),
    smallMobileImage: style({
        objectFit: "cover",
        width: "100%",
        height: 280,
        marginBottom: 20
    }),
    title: style({
        fontSize: "1.125rem",
        fontWeight: "bold",
        marginTop: Dimensions.padding
    }),
    mobileImage: style({
        width: 300,
        height: 212,
        objectFit: "cover",
        cursor: "pointer"
    }),
    portFolioItem: style({
        marginBottom: Dimensions.padding * 2,
        width: 300
    })
};
export const DesignPortfolio = (_props) => {
    const [assets, setAssets] = useState(null);
    useEffect(() => {
        function getAssets() {
            return __awaiter(this, void 0, void 0, function* () {
                let { data: portfolioItems } = yield portfolioItemService.getAll({
                    sort: ["sortOrder"]
                });
                const assets = yield Promise.all(portfolioItems.map((item) => __awaiter(this, void 0, void 0, function* () {
                    if (item.assetType === AssetType.VIMEO_VIDEO)
                        item.thumbImageUrl = yield vimeoService.getThumb(item);
                    return {
                        title: item.title,
                        assetUrl: item.assetType === AssetType.IMAGE
                            ? item.imageUrl
                            : item.vimeoUrl,
                        thumbUrl: item.thumbImageUrl,
                        type: item.assetType
                    };
                })));
                setAssets(assets);
            });
        }
        getAssets();
    }, []);
    const onAssetClick = useRef((e, clickedIndex, assets) => {
        if (assets)
            AssetOverlay.show(assets, clickedIndex);
    });
    return (React.createElement("div", { id: "design-portfolio" },
        React.createElement("div", { className: classNames.desktopWrapper },
            React.createElement(Header, { header: "Enkele voorbeelden", center: true }),
            React.createElement(PortfolioItemGrid, { assets: assets, onAssetClick: onAssetClick.current, showHoverEffect: true, showImageOverlay: true })),
        React.createElement("div", { className: classNames.mobileWrapper },
            React.createElement(Header, { header: "Enkele voorbeelden", center: true }),
            React.createElement("div", { className: classNames.mobileImageContainer }, assets === null || assets === void 0 ? void 0 : assets.map((asset, key) => {
                var _a;
                if (asset.type === AssetType.VIMEO_VIDEO) {
                    return (React.createElement("div", { className: classNames.portFolioItem, key: key },
                        React.createElement("iframe", { title: "vimeo-player", src: asset.assetUrl, width: "300", height: "212", frameBorder: 0, allow: "fullscreen" }),
                        React.createElement("div", { className: classNames.title }, asset.title)));
                }
                return (React.createElement("div", { key: key, className: classNames.portFolioItem, onClick: (e) => onAssetClick.current(e, key, assets) },
                    React.createElement("img", { src: (_a = asset.thumbUrl) !== null && _a !== void 0 ? _a : asset.assetUrl, className: classNames.mobileImage }),
                    React.createElement("div", { className: classNames.title }, asset.title)));
            })))));
};
