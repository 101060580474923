import React, { useRef } from "react";
import { style } from "typestyle";
import { Colors } from "../../../styling/Colors";
import { Dimensions } from "../../../styling/Dimensions";
import { Header } from "../header";
const classNames = {
    desktopWrapper: style({
        $debugName: "desktop-wrapper",
        display: "block",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "none"
        }
    }),
    container: style({
        display: "flex"
    }),
    containerLeft: style({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: Dimensions.padding * 2,
        borderRight: "1px solid black",
        width: 385
    }),
    containerRight: style({
        display: "grid",
        width: "100%",
        marginLeft: Dimensions.padding * 3,
        gridTemplateColumns: "repeat(3, 1fr)"
    }),
    mobileWrapper: style({
        $debugName: "mobile-wrapper",
        display: "none",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "block"
        }
    }),
    header: style({
        color: Colors.orange,
        fontSize: "2rem",
        fontWeight: "bold",
        marginBottom: Dimensions.padding * 2
    }),
    subHeader: style({
        fontWeight: "bold",
        marginBottom: Dimensions.padding * 2
    }),
    logoContainer: style({
        padding: Dimensions.padding * 2
    }),
    logo: style({
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxWidth: 200,
        height: 50,
        margin: "auto"
    }),
    mobileContainer: style({
        display: "flex",
        flexDirection: "column"
    }),
    mobileGrid: style({
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        width: "100%"
    }),
    mobileText: style({
        fontSize: "2rem",
        fontWeight: "bold",
        marginBottom: Dimensions.padding * 2,
        maxWidth: 500
    })
};
export const OurClients = (_props) => {
    const clientUrls = useRef([
        "/logos/mumc-min.png",
        "/logos/mu-min.png",
        "/logos/rumc-min.png",
        "/logos/zuyd-min.png",
        "/logos/jbz-min.jpg",
        // "/logos/breitner-min.png",
        "/logos/nyenrode-min.png",
        "/logos/olvg-min.jpg",
        "/logos/logo-cicero.svg",
        "/logos/logo-envida.svg"
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames.desktopWrapper },
            React.createElement("div", { className: classNames.container },
                React.createElement("div", { className: classNames.containerLeft },
                    React.createElement(Header, { header: "Voor wie wij werken", subHeader: "Uitdagende projecten voor mooie klanten" })),
                React.createElement("div", { className: classNames.containerRight }, clientUrls.current.map((url) => (React.createElement("div", { className: classNames.logoContainer, key: url },
                    React.createElement("div", { className: classNames.logo, style: { backgroundImage: `url(${url})` } }))))))),
        React.createElement("div", { className: classNames.mobileWrapper },
            React.createElement("div", { className: classNames.mobileContainer },
                React.createElement(Header, { header: "Voor wie wij werken", subHeader: "Uitdagende projecten voor mooie klanten", center: true }),
                React.createElement("div", { className: classNames.mobileGrid }, clientUrls.current.map((url) => (React.createElement("div", { className: classNames.logoContainer, key: url },
                    React.createElement("div", { className: classNames.logo, style: { backgroundImage: `url(${url})` } })))))))));
};
