var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AssetType } from "living-window-packages/enums/asset-type.enum";
import { HttpService } from "nest-utilities-client";
export const vimeoService = new (class VimeoService extends HttpService {
    constructor() {
        super(...arguments);
        this.baseUrl = "https://vimeo.com/api/v2/video/";
    }
    getHeaders(url, init) {
        return {};
    }
    onRequestError(error) { }
    getThumb(portfolioItem) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (portfolioItem.assetType === AssetType.VIMEO_VIDEO) {
                const id = portfolioItem.vimeoUrl.split("/").pop();
                const { data: response } = yield this.get(this.baseUrl + id + ".json", {});
                if ((_a = response[0]) === null || _a === void 0 ? void 0 : _a.thumbnail_medium)
                    return response[0].thumbnail_large;
            }
        });
    }
})();
