import React, { useCallback, useEffect, useRef, useState } from "react";
import { style } from "typestyle";
const classNames = {
    container: style({
        display: "flex",
        flexDirection: "column"
    }),
    innerContainer: style({
        display: "flex",
        justifyContent: "center",
        $debugName: "inner-container"
    }),
    arrowContainer: style({
        flex: "0 0 40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    }),
    arrowLeft: style({
        backgroundImage: `url(/svgs/arrow-left-white.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        width: 20,
        height: 20,
        $debugName: "arrow-left"
    }),
    arrowLeftBlack: style({
        backgroundImage: `url(/svgs/arrow-left.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        width: 20,
        height: 20,
        $debugName: "arrow-left"
    }),
    arrowRightBlack: style({
        $debugName: "arrow-right-black",
        backgroundImage: `url(/svgs/arrow-right.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        width: 10,
        height: 20,
        cursor: "pointer"
    }),
    arrowRight: style({
        backgroundImage: `url(/svgs/arrow-right-white.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        width: 10,
        height: 20,
        cursor: "pointer",
        $debugName: "arrow-right"
    }),
    contentContainer: style({
        flex: 1,
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        $debugName: "container-container"
    }),
    textContainerDivider: style({
        margin: "0 auto",
        height: 1.5,
        width: "100%",
        maxWidth: 450
    }),
    navigationContainer: style({
        display: "flex",
        justifyContent: "center"
    }),
    navigationBullet: style({
        borderRadius: "50%",
        cursor: "pointer",
        width: "11px",
        height: "11px",
        border: "2px solid white",
        marginRight: 5,
        marginLeft: 5,
        $nest: {
            "&:hover": {
                cursor: "pointer",
                backgroundColor: "white"
            }
        }
    }),
    navigationBulletBlack: style({
        borderRadius: "50%",
        cursor: "pointer",
        width: "11px",
        height: "11px",
        border: "2px solid #C4C4C4",
        marginRight: 5,
        marginLeft: 5,
        $nest: {
            "&:hover": {
                cursor: "pointer",
                backgroundColor: "#989595"
            }
        }
    }),
    navigationBulletActive: style({
        backgroundColor: "white"
    }),
    navigationBulletBlackActive: style({
        backgroundColor: "#989595"
    })
};
export const ElementSlider = (props) => {
    const [visibleElementIndex, setVisibleElementIndex] = useState(0);
    useEffect(() => {
        if (props.elements.length > 1) {
            const timeout = setTimeout(() => {
                const nextIndex = visibleElementIndex + 1;
                if (props.elements[nextIndex]) {
                    setVisibleElementIndex(nextIndex);
                }
                else {
                    setVisibleElementIndex(0);
                }
            }, 8000);
            return () => clearTimeout(timeout);
        }
    }, [visibleElementIndex, props.elements]);
    const onClickBullet = useRef((currentImageIndex, clickedImageIndex) => {
        if (currentImageIndex !== clickedImageIndex)
            setVisibleElementIndex(clickedImageIndex);
    });
    const onClickArrow = useCallback((next, visibleElementIndex) => {
        if (next === true && props.elements[visibleElementIndex + 1])
            setVisibleElementIndex(visibleElementIndex + 1);
        else if (next === false && props.elements[visibleElementIndex - 1])
            setVisibleElementIndex(visibleElementIndex - 1);
        else if (next === false && !props.elements[visibleElementIndex - 1])
            setVisibleElementIndex(props.elements.length - 1);
        else
            setVisibleElementIndex(0);
    }, [props.elements]);
    const getVisibleElement = useCallback((index) => {
        return props.elements[index];
    }, [props.elements]);
    const getActiveClassName = useRef(() => {
        if (props.navigationColor === "black")
            return classNames.navigationBulletBlackActive;
        return classNames.navigationBulletActive;
    });
    const getNavigationBulletClassName = useRef(() => {
        if (props.navigationColor === "black")
            return classNames.navigationBulletBlack;
        return classNames.navigationBullet;
    });
    return (React.createElement("div", { className: classNames.container },
        React.createElement("div", { className: classNames.innerContainer },
            props.elements.length > 1 && (React.createElement("div", { className: classNames.arrowContainer, onClick: () => onClickArrow(false, visibleElementIndex) },
                React.createElement("div", { className: props.navigationColor === "black"
                        ? classNames.arrowLeftBlack
                        : classNames.arrowLeft }))),
            React.createElement("div", { className: classNames.contentContainer, style: { height: props.elementHeight } }, getVisibleElement(visibleElementIndex)),
            props.elements.length > 1 && (React.createElement("div", { className: classNames.arrowContainer, onClick: () => onClickArrow(true, visibleElementIndex) },
                React.createElement("div", { className: props.navigationColor === "black"
                        ? classNames.arrowRightBlack
                        : classNames.arrowRight })))),
        props.elements.length > 1 && (React.createElement("div", { className: classNames.navigationContainer }, props.elements.map((el, idx) => (React.createElement("div", { key: idx, className: [
                getNavigationBulletClassName.current(),
                el === getVisibleElement(visibleElementIndex)
                    ? getActiveClassName.current()
                    : ""
            ].join(" "), onClick: () => onClickBullet.current(visibleElementIndex, idx) })))))));
};
