import React from "react";
import { style } from "typestyle";
import { routes } from "../../../routing/routes";
import { Colors } from "../../../styling/Colors";
import { Dimensions } from "../../../styling/Dimensions";
import { ExpandableArea } from "../expandableTextArea";
const classNames = {
    desktopWrapper: style({
        $debugName: "desktop-wrapper",
        position: "relative",
        display: "block",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "none"
        }
    }),
    mobileWrapper: style({
        $debugName: "mobile-wrapper",
        display: "none",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "block"
        }
    }),
    firstBlockWrapper: style({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: Dimensions.padding * 6
    }),
    secondBlockWrapper: style({
        $debugName: "second-block-wrapper",
        display: "flex",
        justifyContent: "space-between"
        // flexDirection: "column"
    }),
    block: style({
        $debugName: "block",
        width: "45%"
    }),
    header: style({
        fontSize: "1.5rem",
        fontWeight: "bold",
        marginBottom: Dimensions.padding * 4,
        color: Colors.lightOrange
    }),
    whatWeDoContent: style({
        fontWeight: 300,
        fontSize: "1.5rem",
        marginBottom: 40
    }),
    whatWeDoImg: style({
        width: "60%",
        maxWidth: 400
    }),
    content: style({
        fontWeight: 300
    }),
    bottomContent: style({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: Dimensions.padding * 4
    }),
    bottomContentSide: style({
        fontStyle: "italic",
        fontWeight: 300,
        width: "45%"
    }),
    innerBlock: style({
        display: "flex",
        flexDirection: "column"
    }),
    linkWrapper: style({
        display: "flex",
        justifyContent: "flex-end"
    }),
    link: style({
        width: "45%",
        textDecoration: "none",
        textTransform: "uppercase",
        color: Colors.lightOrange,
        cursor: "pointer",
        fontWeight: "bold",
        borderBottom: "1px solid black",
        paddingBottom: Dimensions.padding * 1.5
    }),
    howWeWorkWrapper: style({
        backgroundColor: Colors.lightGreen
    }),
    howWeWorkHeader: style({
        fontSize: "2rem",
        fontWeight: "bold",
        textAlign: "center",
        color: "white",
        marginBottom: Dimensions.padding * 4
    }),
    mobileBlock: style({
        marginBottom: Dimensions.padding * 4
    }),
    mobileHeader: style({
        fontSize: "1.125rem",
        fontWeight: "bold",
        marginBottom: Dimensions.padding,
        color: Colors.lightOrange
    }),
    whatWeDoMobileImage: style({
        display: "block",
        width: "70%",
        maxWidth: 400,
        margin: "0 auto"
    }),
    mobileExpandableContent: style({
        fontWeight: 300
    }),
    mobileExpandableFirstBlock: style({
        marginBottom: Dimensions.padding * 2
    }),
    mobileExpanbleSecondBlock: style({
        fontStyle: "italic"
    })
};
export const WhatWeDo = (_props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames.desktopWrapper },
            React.createElement("div", { className: classNames.firstBlockWrapper },
                React.createElement("div", { className: classNames.block },
                    React.createElement("div", { className: classNames.header }, "Wat we beloven en doen"),
                    React.createElement("div", { className: classNames.whatWeDoContent }, "Levende strategie\u00EBn en levende organisaties ontwikkelen op weg naar een betere wereld."),
                    React.createElement("img", { src: "/misc/home/visual-home.svg", className: classNames.whatWeDoImg })),
                React.createElement("div", { className: classNames.block },
                    React.createElement("div", { className: classNames.header }, "Partner in ontwikkeling"),
                    React.createElement("div", { className: classNames.content },
                        "Wij geloven dat als je met een andere bril naar jezelf, je omgeving en organisatie kijkt, nieuwe mogelijkheden ontstaan voor meer impact en echte verandering. Wij zijn een belangeloze partner om mee te schakelen en te sparren vanuit visie, verbinding en vertrouwen. Daarbij hebben we oog voor de bedoelingen, belangen en betekenis. We verdiepen ons in de specifieke inhoudelijke, sociale en economische context van het vraagstuk. ",
                        React.createElement("br", null),
                        "Wij geven ruimte aan de verschillende perspectieven rond een strategisch vraagstuk en weten al snel een sfeer van vertrouwen en verbinding te realiseren. ",
                        React.createElement("br", null),
                        "Wij werken samen aan wat noodzakelijk is, mogelijk is en haalbaar is. Daarmee realiseren wij ook het onverwachte. Het is onze wens om jou \u00E9n je organisatie te bemoedigen om keuzes te maken op weg naar het realiseren van je visie.",
                        React.createElement("div", { style: { marginTop: 40 } },
                            React.createElement("i", null,
                                "Samen de volgende stap verkennen: ",
                                React.createElement("br", null),
                                "op naar een betere wereld met levende organisaties."))))),
            React.createElement("div", { className: classNames.secondBlockWrapper },
                React.createElement("div", { className: classNames.block, style: { marginBottom: Dimensions.padding * 4, border: "none" } },
                    React.createElement("div", { className: classNames.header }, "Strategische ontmoetingen"),
                    React.createElement("div", { className: classNames.content }, "Wij cre\u00EBren strategische ontmoetingen waarin wij verschillende relevante stakeholders verleiden tot verdieping, verbeeldingskracht en actieve participatie op het voorliggende vraagstuk. Strategische vraagstukken zijn vaak complex en spelen in een dynamische context. Met verbeeldingskracht krijg je beter zicht op alle dilemma\u2019s en in een strategische dialoog verbind je deze. Dat geeft zicht op keuzes voor duurzame oplossingen en je ervaart de kracht van samenwerken. Zo realiseren we samen een inspirerend toekomstbeeld met een passend strategisch kompas.")),
                React.createElement("div", { className: classNames.block },
                    React.createElement("div", { className: classNames.header }, "Versterking door design"),
                    React.createElement("div", { className: classNames.content },
                        "Wij vertellen jouw visie en verhaal op een visuele en begrijpelijke manier, met onze visuals, infographics en animaties. Wij zijn analytisch en strategisch en verdiepen ons in de voorgelegde context. Wij ontwerpen visuals die de kern van een complex onderwerp samenvatten. Deze zijn uitnodigend en geschikt om de dialoog voort te zetten.",
                        React.createElement("br", null),
                        " Van ontwerp tot pixel-perfect eindresultaat, geeft onze visuele communicatie een krachtige extra dimensie aan strategische bijeenkomsten en de gewenste ontwikkeling."))),
            React.createElement("div", { className: classNames.bottomContent },
                React.createElement("div", { className: classNames.bottomContentSide },
                    "Samen de strategische koers vormgeven: ",
                    React.createElement("br", null),
                    " meer van waarde zijn door levende strategie\u00EBn."),
                React.createElement("div", { className: classNames.bottomContentSide },
                    "Samen jouw boodschap tot leven brengen:",
                    React.createElement("br", null),
                    " meer impact met een visuele storyline.")),
            React.createElement("div", { className: classNames.linkWrapper },
                React.createElement("a", { className: classNames.link, href: routes.design.path.toString() }, "> Meer over onze designs"))),
        React.createElement("div", { className: classNames.mobileWrapper },
            React.createElement("div", { className: classNames.mobileBlock },
                React.createElement("div", { className: classNames.mobileHeader }, "Wat we beloven en doen"),
                React.createElement("div", { className: classNames.whatWeDoContent }, "Levende strategie\u00EBn en levende organisaties ontwikkelen op weg naar een betere wereld."),
                React.createElement("img", { src: "/misc/home/visual-home.svg", className: classNames.whatWeDoMobileImage })),
            React.createElement("div", { className: classNames.mobileBlock },
                React.createElement(ExpandableArea, { title: "Partner in ontwikkeling", isDefaultExpanded: true },
                    React.createElement("div", { className: classNames.mobileExpandableContent },
                        React.createElement("div", { className: classNames.mobileExpandableFirstBlock },
                            "Wij geloven dat als je met een andere bril naar jezelf, je omgeving en organisatie kijkt, nieuwe mogelijkheden ontstaan voor meer impact en echte verandering. Wij zijn een belangeloze partner om mee te schakelen en te sparren vanuit visie, verbinding en vertrouwen. Daarbij hebben we oog voor de bedoelingen, belangen en betekenis. We verdiepen ons in de specifieke inhoudelijke, sociale en economische context van het vraagstuk. Wij geven ruimte aan de verschillende perspectieven rond een strategisch vraagstuk en weten al snel een sfeer van vertrouwen en verbinding te realiseren.",
                            React.createElement("br", null),
                            "Wij werken samen aan wat noodzakelijk is, mogelijk is en haalbaar is. Daarmee realiseren wij ook het onverwachte. Het is onze wens om jou \u00E9n je organisatie te bemoedigen om keuzes te maken op weg naar het realiseren van je visie."),
                        React.createElement("div", { className: classNames.mobileExpanbleSecondBlock },
                            "Samen de volgende stap verkennen:",
                            React.createElement("br", null),
                            " op naar een betere wereld met levende organisaties."))),
                React.createElement(ExpandableArea, { title: "Strategische ontmoetingen" },
                    React.createElement("div", { className: classNames.mobileExpandableContent },
                        React.createElement("div", { className: classNames.mobileExpandableFirstBlock }, "Wij cre\u00EBren strategische ontmoetingen waarin wij verschillende relevante stakeholders verleiden tot verdieping, verbeeldingskracht en actieve participatie op het voorliggende vraagstuk. Strategische vraagstukken zijn vaak complex en spelen in een dynamische context. Met verbeeldingskracht krijg je beter zicht op alle dilemma\u2019s en in een strategische dialoog verbind je deze. Dat geeft zicht op keuzes voor duurzame oplossingen en je ervaart de kracht van samenwerken. Zo realiseren we samen een inspirerend toekomstbeeld met een passend strategisch kompas."),
                        React.createElement("div", { className: classNames.mobileExpanbleSecondBlock },
                            "Samen de strategische koers vormgeven:",
                            React.createElement("br", null),
                            " meer van waarde zijn door levende strategie\u00EBn."))),
                React.createElement(ExpandableArea, { title: "Versterking door design", isLast: true },
                    React.createElement("div", { className: classNames.mobileExpandableContent },
                        React.createElement("div", { className: classNames.mobileExpandableFirstBlock }, "Wij vertellen jouw visie en verhaal op een visuele en begrijpelijke manier, met onze visuals, infographics en animaties. Wij zijn analytisch en strategisch en verdiepen ons in de voorgelegde context. Wij ontwerpen visuals die de kern van een complex onderwerp samenvatten. Deze zijn uitnodigend en geschikt om de dialoog voort te zetten. Van ontwerp tot pixel-perfect eindresultaat, geeft onze visuele communicatie een krachtige extra dimensie aan strategische bijeenkomsten en het de gewenste ontwikkeling."),
                        React.createElement("div", { className: classNames.mobileExpanbleSecondBlock },
                            "Samen jouw boodschap tot leven brengen:",
                            React.createElement("br", null),
                            " meer impact met een visuele storyline.")))))));
};
