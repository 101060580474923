import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { router, Router } from "../routing/router";
import { routes } from "../routing/routes";
import ImageOverlay from "../statics/AssetOverlay";
import MobileMenu from "../statics/MobileMenu";
import Notification from "../statics/Notification";
const classNames = {};
export class Main extends React.Component {
    constructor(props) {
        super(props);
        const url = new URLSearchParams(window.location.search);
        if (url.get("live") === "1")
            localStorage.setItem("live", "1");
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(Router.Element, { history: router.history },
                React.createElement(Switch, null, Object.keys(routes).map((key) => (React.createElement(Route, { key: key, component: routes[key].component, path: routes[key].path, exact: routes[key].exact }))))),
            React.createElement(MobileMenu, null),
            React.createElement(Notification, null),
            React.createElement(ImageOverlay, null)));
    }
}
