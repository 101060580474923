import React, { useEffect, useRef, useState } from "react";
import { keyframes, style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
import { ImageUtils } from "../../utils/image.utils";
import { scrollUtils } from "../../utils/scroll.utils";
const bounceAnimation = keyframes({
    "0%": { transform: "translateY(0px)" },
    "10%": { transform: "translateY(-10px)" },
    "20%": { transform: "translateY(0px)" }
});
const classNames = {
    container: style({
        transition: "background-image 1s ease-in-out",
        overflow: "hidden",
        width: "100%",
        height: `calc((100vh - ${Dimensions.navBarHeight}px))`,
        position: "relative",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover"
    }),
    overlay: style({
        zIndex: 1,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(20,13,40, 0.4)"
    }),
    contentContainer: style({
        position: "absolute",
        overflow: "hidden",
        zIndex: 2,
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "white",
        fontFamily: "Open Sans",
        fontSize: "2.81rem",
        fontWeight: "bold",
        userSelect: "none",
        width: "90%",
        textAlign: "center",
        $nest: {
            "@media screen and (max-width: 1200px)": {
                fontSize: "1.4rem"
            }
        }
    }),
    arrowDownContainer: style({
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        zIndex: 2,
        bottom: 20,
        left: 0,
        right: 0
    }),
    arrowDown: style({
        cursor: "pointer",
        width: 35,
        height: 35,
        animationName: bounceAnimation,
        animationDuration: "3s",
        animationIterationCount: "infinite"
    }),
    textContainerDivider: style({
        margin: "0 auto",
        backgroundColor: Colors.orange,
        height: 3,
        width: "100%",
        maxWidth: 600
    }),
    quoteWrapper: style({
        display: "flex",
        textAlign: "center",
        fontWeight: 400,
        paddingBottom: 60,
        minHeight: 220,
        letterSpacing: 5
    }),
    quote: style({
        alignSelf: "flex-end",
        width: "100%"
    }),
    author: style({
        paddingTop: 60,
        fontSize: "1.4rem",
        fontWeight: "normal",
        letterSpacing: 5,
        $nest: {
            "@media screen and (max-width: 1200px)": {
                fontSize: "1.0rem"
            }
        }
    }),
    navigationContainer: style({
        paddingTop: 20,
        display: "flex",
        justifyContent: "center"
    }),
    navigationBullet: style({
        borderRadius: "50%",
        cursor: "pointer",
        width: "11px",
        height: "11px",
        border: "2px solid white",
        marginRight: 5,
        marginLeft: 5,
        $nest: {
            "&:hover": {
                backgroundColor: "white"
            }
        }
    }),
    navigationBulletActive: style({
        backgroundColor: "white"
    })
};
export const QuoteSlider = (props) => {
    var _a;
    const [visibleImageIndex, setVisibleImageIndex] = useState(0);
    useEffect(() => {
        ImageUtils.preLoadImages(props.images.map((img) => img.imageUrl));
    }, []);
    useEffect(() => {
        if (props.images.length > 1) {
            const timeout = setTimeout(() => {
                const nextIndex = visibleImageIndex + 1;
                if (props.images[nextIndex]) {
                    setVisibleImageIndex(nextIndex);
                }
                else {
                    setVisibleImageIndex(0);
                }
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [visibleImageIndex]);
    const onClickBullet = useRef((currentImageIndex, clickedImageIndex) => {
        if (currentImageIndex !== clickedImageIndex)
            setVisibleImageIndex(clickedImageIndex);
    });
    const onClickArrowDown = useRef(() => {
        scrollUtils.smoothScroll(window.innerHeight);
    });
    const getVisibleImage = useRef((index) => {
        return props.images[index];
    });
    return (React.createElement("div", { className: classNames.container, style: {
            backgroundImage: `url(${getVisibleImage.current(visibleImageIndex).imageUrl})`
        } },
        React.createElement("div", { className: classNames.overlay }),
        React.createElement("div", { className: classNames.contentContainer },
            React.createElement("div", { className: classNames.quoteWrapper },
                React.createElement("div", { className: classNames.quote }, getVisibleImage.current(visibleImageIndex).topElement)),
            React.createElement("div", { className: classNames.textContainerDivider }),
            React.createElement("div", { className: classNames.author }, (_a = getVisibleImage.current(visibleImageIndex)) === null || _a === void 0 ? void 0 : _a.subText),
            props.images.length > 1 && (React.createElement("div", { className: classNames.navigationContainer }, props.images.map((image, idx) => (React.createElement("div", { key: idx, className: [
                    classNames.navigationBullet,
                    idx === visibleImageIndex
                        ? classNames.navigationBulletActive
                        : ""
                ].join(" "), onClick: () => onClickBullet.current(visibleImageIndex, idx) })))))),
        React.createElement("div", { className: classNames.arrowDownContainer },
            React.createElement("img", { src: "/svgs/circle-arrow-down.svg", className: classNames.arrowDown, onClick: onClickArrowDown.current, style: props.arrowAnimationDelay
                    ? { animationDelay: props.arrowAnimationDelay + "s" }
                    : {} }))));
};
