import React, { useRef } from "react";
import { style } from "typestyle";
import { Router } from "../../routing/router";
import { routes } from "../../routing/routes";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    desktopContainer: style({
        width: "100%",
        backgroundColor: Colors.darkGreen,
        color: "white",
        $nest: {
            [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
                display: "none"
            }
        }
    }),
    innerContainer: style({
        margin: "0 auto",
        height: "100%",
        maxWidth: Dimensions.maxUIWidth,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: `${Dimensions.padding * 6}px ${Dimensions.padding * 2}px ${Dimensions.padding * 6}px ${Dimensions.padding}px `
    }),
    topBar: style({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 20
    }),
    topbarLeft: style({}),
    topbarRight: style({
        display: "flex"
    }),
    topbarRightNavItem: style({
        color: Colors.white,
        fontWeight: "bold",
        marginLeft: Dimensions.padding * 3,
        textTransform: "uppercase",
        cursor: "pointer",
        userSelect: "none"
    }),
    navItem: style({
        fontFamily: "Open Sans",
        fontWeight: 600,
        letterSpacing: "1px",
        fontSize: 12,
        cursor: "pointer",
        userSelect: "none",
        marginLeft: "65px",
        display: "inline-block",
        textTransform: "uppercase",
        $nest: {
            "&::before": { display: "block", height: 10, content: "''" },
            "&::after": {
                height: 10,
                display: "block",
                transform: "scaleX(0)",
                content: "''",
                borderBottom: `2px solid ${Colors.orange}`,
                transition: "transform 250ms ease-in-out"
            },
            "&:hover": {
                color: Colors.orange,
                $nest: {
                    "&::after": {
                        transform: "scaleX(1)"
                    }
                }
            }
        }
    }),
    navItemActive: style({
        color: Colors.orange,
        $nest: {
            "&::after": {
                height: 10,
                display: "block",
                content: "''",
                borderBottom: `2px solid ${Colors.orange}`,
                width: "100%",
                transition: "none",
                transform: "scaleX(1)"
            }
        }
    }),
    bottomBar: style({
        display: "flex",
        marginTop: 20,
        justifyContent: "space-between",
        color: "white"
    }),
    bottomLeft: style({
        display: "flex",
        flexDirection: "column"
    }),
    bottomRight: style({
        display: "flex"
    }),
    blockWrapper: style({
        display: "flex",
        flexDirection: "row",
        marginBottom: 20
    }),
    locationLogo: style({
        backgroundImage: `url('/svgs/location.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: 20,
        height: 20,
        marginTop: 4,
        marginRight: 15,
        lineHeight: "170%"
    }),
    phoneLogo: style({
        backgroundImage: `url('/svgs/phone.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: 20,
        height: 20,
        marginTop: 4,
        marginRight: 15,
        lineHeight: "170%"
    }),
    linkedInLogo: style({
        backgroundImage: `url('/svgs/linkedin.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: 20,
        height: 20,
        cursor: "pointer",
        $nest: {
            "&:hover": {
                backgroundImage: `url('/svgs/linkedin-orange.svg')`
            }
        }
    }),
    mailLogo: style({
        backgroundImage: `url('/svgs/mail.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: 20,
        height: 20,
        marginLeft: Dimensions.padding,
        cursor: "pointer",
        $nest: {
            "&:hover": {
                backgroundImage: `url('/svgs/mail-orange.svg')`
            }
        }
    }),
    phone: style({ textDecoration: "none", color: Colors.white }),
    blockText: style({
        display: "flex",
        flexDirection: "column"
    }),
    textLine: style({
        fontWeight: 300,
        lineHeight: "170%"
    }),
    logo: style({
        backgroundImage: `url('/svgs/logolw-grijs.svg')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: 200,
        height: 35
    }),
    line: style({
        width: "100%",
        height: 1,
        border: "1px solid white"
    }),
    copyRight: style({})
};
export const FooterDesktop = (props) => {
    const isNavItemActive = useRef((forRoutePath = "home") => {
        if (forRoutePath === "home")
            return window.location.pathname === "/";
        return window.location.pathname.includes(forRoutePath);
    });
    const goToLinkedIn = useRef(() => {
        window.open("https://www.linkedin.com/company/livingwindow", "_blank");
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames.desktopContainer },
            React.createElement("div", { className: classNames.innerContainer },
                React.createElement("div", { className: classNames.topBar },
                    React.createElement("div", { className: classNames.topbarLeft },
                        React.createElement("div", { className: classNames.logo })),
                    React.createElement("div", { className: classNames.topbarRight },
                        React.createElement("div", { className: [
                                classNames.navItem,
                                isNavItemActive.current() ? classNames.navItemActive : ""
                            ]
                                .filter(Boolean)
                                .join(" "), onClick: () => Router.instance.navigate(routes.home.path) }, "Home"),
                        React.createElement("div", { className: [
                                classNames.navItem,
                                isNavItemActive.current(routes.design.path)
                                    ? classNames.navItemActive
                                    : ""
                            ]
                                .filter(Boolean)
                                .join(" "), onClick: () => Router.instance.navigate(routes.design.path) }, "Design"))),
                React.createElement("div", { className: classNames.line }),
                React.createElement("div", { className: classNames.bottomBar },
                    React.createElement("div", { className: classNames.bottomLeft },
                        React.createElement("div", { className: classNames.blockWrapper },
                            React.createElement("div", { className: classNames.phoneLogo }),
                            React.createElement("div", { className: classNames.blockText },
                                React.createElement("div", { className: classNames.textLine },
                                    React.createElement("a", { className: classNames.phone, href: "tel:+31 6 14 16 84 23" }, "+31 6 14 16 84 23"))))),
                    React.createElement("div", { className: classNames.bottomRight },
                        React.createElement("div", { className: classNames.linkedInLogo, onClick: goToLinkedIn.current }),
                        React.createElement("a", { href: "mailto:inge.roosen@livingwindow.nl", className: classNames.mailLogo })))))));
};
