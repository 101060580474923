import { AssetType } from "living-window-packages/enums/asset-type.enum";
import React, { useCallback } from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    container: style({
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        columnGap: 30,
        rowGap: 50
    }),
    imageContainer: style({
        position: "relative"
    }),
    image: style({
        objectFit: "cover",
        width: "100%",
        height: 190,
        cursor: "pointer"
    }),
    vimeoImage: style({
        objectFit: "cover",
        objectPosition: "50% 50%",
        width: "100%",
        height: 190,
        cursor: "pointer"
    }),
    imageHoverOverlayContainer: style({
        cursor: "pointer",
        opacity: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        $nest: {
            "&:hover": {
                transition: "opacity .4s ease-in-out",
                opacity: 1
            }
        }
    }),
    imageHoverTitle: style({
        fontSize: "1rem",
        textAlign: "center",
        color: "white",
        fontWeight: "bold",
        padding: Dimensions.padding,
        flex: 1,
        overflow: "hidden"
    }),
    noPointer: style({
        cursor: "none"
    })
};
export const PortfolioItemGrid = (props) => {
    var _a;
    const onAssetClick = useCallback((e, index) => {
        props.onAssetClick(e, index, props.assets);
    }, [props.assets]);
    return (React.createElement("div", { className: classNames.container }, (_a = props.assets) === null || _a === void 0 ? void 0 : _a.map((asset, idx) => {
        var _a, _b;
        if (asset.type === AssetType.IMAGE) {
            return (React.createElement("div", { className: classNames.imageContainer, key: idx, onClick: (e) => {
                    onAssetClick(e, idx);
                } },
                React.createElement("img", { className: [
                        classNames.image,
                        props.showImageOverlay !== true ? classNames.noPointer : ""
                    ]
                        .filter(Boolean)
                        .join(" "), src: (_a = asset.thumbUrl) !== null && _a !== void 0 ? _a : asset.assetUrl, key: idx }),
                props.showHoverEffect === true && (React.createElement("div", { className: classNames.imageHoverOverlayContainer },
                    React.createElement("div", { className: classNames.imageHoverTitle }, asset.title)))));
        }
        else if (asset.type === AssetType.VIMEO_VIDEO) {
            return (React.createElement("div", { className: classNames.imageContainer, key: idx, onClick: (e) => {
                    onAssetClick(e, idx);
                } },
                React.createElement("img", { className: [
                        classNames.vimeoImage,
                        props.showImageOverlay !== true ? classNames.noPointer : ""
                    ]
                        .filter(Boolean)
                        .join(" "), src: (_b = asset.thumbUrl) !== null && _b !== void 0 ? _b : asset.assetUrl, key: idx }),
                props.showHoverEffect === true && (React.createElement("div", { className: classNames.imageHoverOverlayContainer },
                    React.createElement("div", { className: classNames.imageHoverTitle }, asset.title)))));
        }
    })));
};
