export const ImageUtils = new (class ImageUtils {
    constructor() {
        this.linkElements = [];
    }
    preLoadImages(imageUrls) {
        imageUrls.forEach((url) => {
            if (this.imageLinkExists(url) === false) {
                const linkElement = document.createElement("link");
                linkElement.setAttribute("rel", "preload");
                linkElement.setAttribute("href", url);
                linkElement.setAttribute("as", "image");
                linkElement.setAttribute("id", url);
                // save the created link element
                this.linkElements.push(linkElement);
                document.getElementsByTagName("body")[0].appendChild(linkElement);
            }
        });
    }
    imageLinkExists(url) {
        const foundElement = this.linkElements.find((element) => element.id === url);
        return typeof foundElement !== "undefined";
    }
})();
