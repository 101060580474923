import { createBrowserHistory } from "history";
import { Router as ReactRouter } from "react-router-dom";
export class Router {
    constructor() {
        /**
         * Browser history object
         */
        this.history = createBrowserHistory();
        this.navigate = (to, searchParams) => {
            const html = document.getElementsByTagName("html")[0];
            html.style.scrollBehavior = "auto";
            // make sure page starts at scroll position 0,0
            window.scrollTo(0, 0);
            this.history.push(to, searchParams);
        };
    }
}
Router.Element = ReactRouter;
Router.instance = new Router();
export const router = Router.instance;
