import React, { useRef } from "react";
import { style } from "typestyle";
import { Page } from "../elements/page.element";
import { PageInnerContainer } from "../elements/pageInner.element";
import { QuoteSlider } from "../elements/quoteSlider";
import { Colors } from "../../styling/Colors";
import { HowWeWork } from "../elements/home/howWeWork";
import { WhatWeDo } from "../elements/home/whatWeDo";
import { OurClients } from "../elements/home/ourClients";
import { OurTeamMembers } from "../elements/home/ourTeamMembers";
import { Dimensions } from "../../styling/Dimensions";
const backgroundHeight = 200;
const hideBackgroundWidth = Dimensions.maxUIWidth + backgroundHeight + backgroundHeight / 2;
const classNames = {
    noPadding: style({
        padding: 0
    }),
    paddingTop: style({
        padding: `${Dimensions.padding * 8}px 0 0 0`
    }),
    greyBackground: style({
        backgroundColor: Colors.lightgrey
    }),
    howWeWorkContainer: style({
        backgroundColor: Colors.lightGreen
    }),
    background: style({
        position: "absolute",
        width: backgroundHeight,
        height: backgroundHeight,
        backgroundImage: `url('/misc/home/visual-home.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        overflow: "hidden",
        transform: "rotate(30)",
        [`@media screen and (max-width: ${hideBackgroundWidth}px)`]: {
            display: "none"
        }
    })
};
export const Home = (_props) => {
    const quoteSliderImages = useRef([
        {
            imageUrl: "/misc/home/slider-1.jpg",
            topElement: React.createElement("div", null, "Strategy is a pattern in a stream of decisions"),
            subText: "Henry Mintzberg"
        },
        {
            imageUrl: "/misc/home/slider-2.jpg",
            topElement: (React.createElement(React.Fragment, null,
                "Start by doing what's necessary, then do what's ",
                React.createElement("br", null),
                " possible and suddenly you are doing the impossible")),
            subText: "Franciscus van Assisi"
        },
        {
            imageUrl: "/misc/home/slider-3.jpg",
            topElement: (React.createElement(React.Fragment, null,
                "You have power over your mind, not outside events.",
                React.createElement("br", null),
                " Realize this, and you will find strength")),
            subText: "Marcus Aurelius"
        },
        {
            imageUrl: "/misc/home/slider-4.jpg",
            topElement: (React.createElement(React.Fragment, null,
                "Wat een mens redt, ",
                React.createElement("br", null),
                "is een stap nemen en dan nog een stap")),
            subText: "Antoine de Saint-Exupéry"
        }
    ]);
    const officeImages = useRef([
        "/misc/home/office-1.jpg",
        "/misc/home/office-2.jpg",
        "/misc/home/office-3.jpg",
        "/misc/home/office-4.jpg"
    ]);
    const getBackgroundElement = useRef((position = "right", backgroundUrl) => {
        const inlineStyle = {
            bottom: 0
        };
        if (position === "left")
            inlineStyle.left = 0;
        if (position === "right")
            inlineStyle.right = 0;
        inlineStyle.backgroundImage = `url(${backgroundUrl})`;
        return React.createElement("div", { className: classNames.background, style: inlineStyle });
    });
    return (React.createElement(Page, null,
        React.createElement(PageInnerContainer, { isTopContent: true, isFullWidth: true, className: classNames.noPadding },
            React.createElement(QuoteSlider, { images: quoteSliderImages.current, arrowAnimationDelay: 15 })),
        React.createElement(PageInnerContainer, { backgroundElement: getBackgroundElement.current("right", "/misc/corners/home-corner-1.svg") },
            React.createElement(WhatWeDo, null)),
        React.createElement(PageInnerContainer, { isFullWidth: true, className: classNames.howWeWorkContainer },
            React.createElement(HowWeWork, null)),
        React.createElement(PageInnerContainer, { backgroundElement: getBackgroundElement.current("left", "/misc/corners/home-corner-2.svg") },
            React.createElement(OurClients, null)),
        React.createElement(PageInnerContainer, { backgroundElement: getBackgroundElement.current("right", "/misc/corners/home-corner-3.svg"), isFullWidth: true, className: classNames.greyBackground },
            React.createElement(OurTeamMembers, null))));
};
