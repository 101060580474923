import React from "react";
import { classes, style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    container: style({
        fontSize: "0.9rem",
        textAlign: "center",
        maxWidth: 275,
        minWidth: 250,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flexStart",
        padding: `0 ${Dimensions.padding * 4}px`
    }),
    image: style({
        borderRadius: "50%",
        width: 150,
        height: 150,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        margin: "0 auto",
        marginBottom: Dimensions.padding * 2
    }),
    mainInfoContainer: style({
        marginBottom: Dimensions.padding * 3,
        lineHeight: "150%"
    }),
    bold: style({
        fontWeight: "bold"
    }),
    light: style({
        fontWeight: 300
    }),
    contactContainer: style({
        $debugName: "contact-info",
        color: Colors.orange,
        lineHeight: "150%",
        minHeight: 70
    }),
    contactInfo: style({
        display: "block",
        fontWeight: "bold",
        color: Colors.orange,
        lineHeight: "150%",
        $nest: {
            "&:hover": {
                color: "black"
            }
        }
    }),
    text: style({
        fontStyle: "italic",
        marginBottom: Dimensions.padding * 3
    }),
    noPadding: style({
        padding: 0
    })
};
export const Employee = (props) => {
    const { employee } = props;
    return (React.createElement("div", { className: classes(classNames.container, props.noPadding === true && classNames.noPadding, props.className) },
        React.createElement("div", { className: classNames.image, style: { backgroundImage: `url(${props.employee.profilePictureUrl})` } }),
        React.createElement("div", { className: classNames.mainInfoContainer },
            React.createElement("div", { className: classNames.bold },
                employee.firstname,
                " ",
                employee.lastname),
            React.createElement("div", { className: classNames.light }, employee.position)),
        React.createElement("div", { className: classNames.text }, employee.description),
        React.createElement("div", { className: classNames.contactContainer },
            React.createElement("a", { href: "tel:" + employee.phonenumber, className: classNames.contactInfo }, employee.phonenumber),
            React.createElement("a", { href: "mailto:" + employee.email, className: classNames.contactInfo }, employee.email))));
};
