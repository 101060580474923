import React, { useRef } from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
import { Footer } from "./pageFooter.element";
import { NavigationBar } from "./pageNavigationBar.element";
const classNames = {
    container: style({
        overflow: "hidden"
    }),
    page: style({
        $debugName: "page",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%"
    }),
    fullHeight: style({
        margin: "0 auto",
        minHeight: "100vh"
    }),
    centeredContent: style({
        paddingTop: `calc((100vh - ${Dimensions.navBarHeight}px) / 6)`
    })
};
export const Page = (props) => {
    const pageClassNames = useRef([
        classNames.page,
        props.hideNavbar ? classNames.fullHeight : "",
        props.centerContent ? classNames.centeredContent : " "
    ].join(" "));
    return (React.createElement("div", { className: classNames.container },
        props.hideNavbar !== true && React.createElement(NavigationBar, null),
        React.createElement("div", { className: pageClassNames.current }, props.children),
        props.hideFooter !== true && React.createElement(Footer, null)));
};
