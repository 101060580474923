import React, { useRef } from "react";
import { style } from "typestyle";
import { Colors } from "../../styling/Colors";
import { Dimensions } from "../../styling/Dimensions";
import { scrollUtils } from "../../utils/scroll.utils";
import { DesignContent } from "../elements/design/designContent";
import { DesignPortfolio } from "../elements/design/designPortfolio";
import { Page } from "../elements/page.element";
import { PageInnerContainer } from "../elements/pageInner.element";
import { QuoteSlider } from "../elements/quoteSlider";
const backgroundHeight = 200;
const correction = 150;
const hideBackgroundWidth = Dimensions.maxUIWidth + backgroundHeight + backgroundHeight / 2;
const classNames = {
    noPadding: style({
        padding: 0
    }),
    videoContainer: style({
        display: "flex",
        justifyContent: "center",
        backgroundColor: Colors.lightGreen
    }),
    background: style({
        position: "absolute",
        width: backgroundHeight,
        height: backgroundHeight,
        backgroundImage: `url('/misc/home/visual-home.svg')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        overflow: "hidden",
        [`@media screen and (max-width: ${hideBackgroundWidth}px)`]: {
            display: "none"
        }
    })
};
export const Design = (_props) => {
    const getBackgroundElement = useRef((position = "right", backgroundUrl) => {
        const inlineStyle = {
            bottom: 0
        };
        if (position.includes("left"))
            inlineStyle.left = 0;
        if (position.includes("right"))
            inlineStyle.right = 0;
        if (position.includes("top"))
            inlineStyle.top = 0;
        inlineStyle.backgroundImage = `url(${backgroundUrl})`;
        return React.createElement("div", { className: classNames.background, style: inlineStyle });
    });
    const onClickDesignPortfolio = useRef(() => {
        const element = document.getElementById("design-portfolio");
        if (element) {
            const scrollTop = window.scrollY + element.getBoundingClientRect().top;
            scrollUtils.smoothScroll(scrollTop);
        }
    });
    return (React.createElement(Page, null,
        React.createElement(PageInnerContainer, { isTopContent: true, isFullWidth: true, className: classNames.noPadding },
            React.createElement(QuoteSlider, { images: [
                    {
                        imageUrl: "/misc/design/slider-1.jpg",
                        topElement: (React.createElement(React.Fragment, null,
                            "Wij vertellen jouw verhaal op een visuele, ",
                            React.createElement("br", null),
                            " inspirerende en begrijpelijke manier")),
                        subText: "Infographics, animaties, illustraties en werkvormen"
                    }
                ], arrowAnimationDelay: 5 })),
        React.createElement(PageInnerContainer, { backgroundElement: getBackgroundElement.current("right", "/misc/corners/home-corner-1.svg") },
            React.createElement(DesignContent, { onClickDesignPortfolio: onClickDesignPortfolio.current })),
        React.createElement(PageInnerContainer, { isFullWidth: true, className: classNames.videoContainer },
            React.createElement("iframe", { title: "vimeo-player", src: "https://player.vimeo.com/video/210393189", width: "840", height: "560", frameBorder: 0, allow: "fullscreen" })),
        React.createElement(PageInnerContainer, { backgroundElement: getBackgroundElement.current("top-right", "/misc/corners/design-corner-1.svg") },
            React.createElement(DesignPortfolio, null))));
};
