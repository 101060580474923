var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import { style } from "typestyle";
import { employeeService } from "../../../services/employee.service";
import { Dimensions } from "../../../styling/Dimensions";
import { ElementSlider } from "../elementSlider";
import { Employee } from "../employee";
import { Header } from "../header";
const classNames = {
    desktopWrapper: style({
        $debugName: "desktop-wrapper",
        display: "block",
        // columnGap: Dimensions.padding,
        // rowGap: Dimensions.padding * 4,
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "none"
        }
    }),
    teamDesktopWrapper: style({
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        width: "100%"
    }),
    employee: style({
        padding: `0 20px`
    }),
    subText: style({
        fontWeight: 300,
        fontSize: "1rem",
        maxWidth: 540,
        margin: "0 auto",
        textAlign: "center",
        marginBottom: Dimensions.padding * 4
    }),
    mobileWrapper: style({
        $debugName: "mobile-wrapper",
        display: "none",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "block"
        }
    }),
    elementMobileHeader: style({
        marginBottom: 50
    })
};
export const OurTeamMembers = (_props) => {
    const [employees, setEmployees] = useState([]);
    useEffect(() => {
        function getEmployees() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data: employees } = yield employeeService.getAll({
                    sort: ["sortOrder"]
                });
                setEmployees(employees);
            });
        }
        getEmployees();
    }, []);
    const tempEmployees = useRef([
        {
            profilePictureUrl: "/team/inge.jpg",
            firstname: "Inge",
            lastname: "",
            position: "strategisch consultant",
            phonenumber: "+31 6 14 16 84 23",
            email: "inge.roosen@livingwindow.nl",
            description: "Met vertrouwen, verbindingen leggen en stappen zetten op weg naar je visie"
        },
        {
            profilePictureUrl: "/team/luc.jpg",
            firstname: "Luc",
            lastname: "",
            position: "consultant",
            phonenumber: "+31 6 26 97 93 91",
            email: "luc.hartmann@livingwindow.nl",
            description: "Vanuit inzicht en verbindingen bouwen aan begrip en resultaat"
        },
        {
            profilePictureUrl: "/team/mary-ann.jpg",
            firstname: "Mary-Ann Nikkel",
            lastname: "",
            position: "office manager",
            phonenumber: "+31 6 30 34 36 21",
            email: "mary-ann.nikkel@livingwindow.nl",
            description: "Met enthousiasme faciliteren zodat anderen kunnen excelleren"
        },
        {
            profilePictureUrl: "/team/job.jpg",
            firstname: "Job",
            lastname: "",
            position: "designer",
            phonenumber: "+31 6 28 48 07 84",
            email: "job.bos@livingwindow.nl",
            description: "Vanuit pixel-perfectie stilstaand en bewegend beeld animeren"
        },
        {
            profilePictureUrl: "/team/job.jpg",
            firstname: "Job",
            lastname: "",
            position: "designer",
            phonenumber: "+31 6 28 48 07 84",
            email: "job.bos@livingwindow.nl",
            description: "Vanuit pixel-perfectie stilstaand en bewegend beeld animeren"
        },
        {
            profilePictureUrl: "/team/job.jpg",
            firstname: "Job",
            lastname: "",
            position: "designer",
            phonenumber: "+31 6 28 48 07 84",
            email: "job.bos@livingwindow.nl",
            description: "Vanuit pixel-perfectie stilstaand en bewegend beeld animeren"
        }
    ]);
    const employeeElements = useRef((employees) => {
        return employees.map((employee, idx) => (React.createElement(Employee, { employee: employee, key: idx, noPadding: true, className: classNames.employee })));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames.desktopWrapper },
            React.createElement(Header, { header: "Wie we zijn", center: true }),
            React.createElement("div", { className: classNames.subText }, "Wij worden gedreven door ons verlangen om te werken aan bijzondere opdrachten in een maatschappelijk relevante context. Wij werken voor, door en samen aan wat noodzakelijk is, mogelijk is en haalbaar is. Daarmee realiseren wij ook het onverwachte."),
            React.createElement("div", { className: classNames.teamDesktopWrapper }, employeeElements.current(employees !== null && employees !== void 0 ? employees : tempEmployees.current))),
        React.createElement("div", { className: classNames.mobileWrapper },
            React.createElement(Header, { header: "Wie we zijn", center: true, className: classNames.elementMobileHeader }),
            React.createElement(ElementSlider, { elements: employeeElements.current(employees !== null && employees !== void 0 ? employees : tempEmployees.current), elementHeight: 500, navigationColor: "black" }))));
};
