var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { style } from "typestyle";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Dimensions } from "../styling/Dimensions";
import { AssetType } from "living-window-packages/enums/asset-type.enum";
export default class AssetOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assets: [],
            currentIndex: 0
        };
        this.classNames = {
            container: style({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                visibility: "hidden",
                position: "fixed",
                width: "100vw",
                height: "100vh",
                top: 0,
                left: 0,
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                color: "white",
                zIndex: 10,
                padding: `0px ${Dimensions.padding}px`
            }),
            topArea: style({
                width: "100%",
                height: "15%",
                padding: `0px ${Dimensions.padding * 1.5}px`
            }),
            titleContainer: style({
                width: "100%",
                color: "white",
                fontSize: "2rem",
                fontWeight: "bold",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "15%",
                $nest: {
                    "@media screen and (max-width: 1000px)": {
                        fontSize: "1.4rem"
                    }
                }
            }),
            imageNavContainer: style({
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                height: "50%"
            }),
            navigationContainer: style({
                display: "flex",
                justifyContent: "center",
                marginTop: Dimensions.padding * 2,
                height: "20%",
                flexFlow: "wrap"
            }),
            crossButton: style({
                width: 23,
                height: "100%",
                marginLeft: "auto",
                backgroundImage: `url('/svgs/cross.svg')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                cursor: "pointer",
                outline: 0
            }),
            innerContainer: style({
                display: "flex",
                justifyContent: "center",
                height: "100%"
            }),
            arrowContainer: style({
                flex: "0 0 40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
            }),
            arrowLeft: style({
                backgroundImage: `url(/svgs/arrow-left-white.svg)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                width: 20,
                height: 20,
                $debugName: "arrow-left"
            }),
            arrowLeftBlack: style({
                backgroundImage: `url(/svgs/arrow-left.svg)`
            }),
            arrowRightBlack: style({
                $debugName: "arrow-right-black",
                backgroundImage: `url(/svgs/arrow-right.svg)`
            }),
            arrowRight: style({
                backgroundImage: `url(/svgs/arrow-right-white.svg)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                width: 10,
                height: 20,
                cursor: "pointer",
                $debugName: "arrow-right"
            }),
            contentContainer: style({
                flex: 1,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center"
            }),
            navigationBullet: style({
                borderRadius: "50%",
                cursor: "pointer",
                width: "11px",
                height: "11px",
                border: "2px solid white",
                marginRight: 5,
                marginLeft: 5,
                $nest: {
                    "&:hover": {
                        backgroundColor: "white"
                    }
                }
            }),
            navigationBulletBlack: style({
                borderRadius: "50%",
                cursor: "pointer",
                width: "11px",
                height: "11px",
                border: "2px solid #C4C4C4",
                marginRight: 5,
                marginLeft: 5,
                $nest: {
                    "&:hover": {
                        backgroundColor: "#989595"
                    }
                }
            }),
            navigationBulletActive: style({
                backgroundColor: "white"
            }),
            navigationBulletBlackActive: style({
                backgroundColor: "#989595"
            }),
            image: style({
                width: "100%",
                height: "100%",
                objectFit: "contain",
                maxWidth: 1600
            })
        };
        this.navMenu = React.createRef();
        AssetOverlay.instance = this;
    }
    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }
    static show(assets, currentIndex = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            const _this = AssetOverlay.instance;
            _this.setState({ assets, currentIndex }, () => {
                setTimeout(() => {
                    _this.navMenu.current.style.opacity = "1";
                    _this.navMenu.current.style.pointerEvents = "auto";
                    _this.navMenu.current.style.visibility = "visible";
                }, 100);
            });
            disableBodyScroll(_this.navMenu.current);
        });
    }
    static hide() {
        return __awaiter(this, void 0, void 0, function* () {
            const _this = AssetOverlay.instance;
            _this.navMenu.current.style.opacity = "0";
            _this.navMenu.current.style.pointerEvents = "none";
            _this.navMenu.current.style.visibility = "hidden";
            enableBodyScroll(_this.navMenu.current);
        });
    }
    onClickArrow(next, visibleElementIndex) {
        const { assets } = this.state;
        if (next === true && assets[visibleElementIndex + 1])
            this.setState({ currentIndex: visibleElementIndex + 1 });
        else if (next === false && assets[visibleElementIndex - 1])
            this.setState({ currentIndex: visibleElementIndex - 1 });
        else if (next === false && !assets[visibleElementIndex - 1])
            this.setState({ currentIndex: assets.length - 1 });
        else
            this.setState({ currentIndex: 0 });
    }
    onClickBullet(index) {
        if (this.state.currentIndex !== index) {
            this.setState({
                currentIndex: index
            });
        }
    }
    render() {
        var _a, _b, _c;
        const { assets, currentIndex } = this.state;
        return (React.createElement("div", { className: this.classNames.container, ref: this.navMenu },
            React.createElement("div", { className: this.classNames.topArea },
                React.createElement("div", { className: this.classNames.crossButton, onClick: () => AssetOverlay.hide() })),
            assets.some((asset) => { var _a; return ((_a = asset.title) === null || _a === void 0 ? void 0 : _a.length) > 0; }) && (React.createElement("div", { className: this.classNames.titleContainer },
                React.createElement("span", null, (_a = assets[currentIndex].title) !== null && _a !== void 0 ? _a : ""))),
            React.createElement("div", { className: this.classNames.imageNavContainer },
                React.createElement("div", { className: this.classNames.innerContainer },
                    this.state.assets.length > 1 && (React.createElement("div", { className: this.classNames.arrowContainer, onClick: () => this.onClickArrow(false, this.state.currentIndex) },
                        React.createElement("div", { className: this.classNames.arrowLeft }))),
                    React.createElement("div", { className: this.classNames.contentContainer },
                        ((_b = assets[currentIndex]) === null || _b === void 0 ? void 0 : _b.type) === AssetType.IMAGE && (React.createElement("img", { src: assets[currentIndex].assetUrl, className: this.classNames.image })),
                        ((_c = assets[currentIndex]) === null || _c === void 0 ? void 0 : _c.type) === AssetType.VIMEO_VIDEO && (React.createElement("iframe", { title: "vimeo-player", allow: "fullscreen", src: assets[currentIndex].assetUrl, width: "100%", height: "100%", frameBorder: 0 }))),
                    this.state.assets.length > 1 && (React.createElement("div", { className: this.classNames.arrowContainer, onClick: () => this.onClickArrow(true, this.state.currentIndex) },
                        React.createElement("div", { className: this.classNames.arrowRight }))))),
            assets.length > 1 && (React.createElement("div", { className: this.classNames.navigationContainer }, assets.map((img, idx) => (React.createElement("div", { key: idx, className: [
                    this.classNames.navigationBullet,
                    this.state.currentIndex === idx
                        ? this.classNames.navigationBulletActive
                        : ""
                ].join(" "), onClick: () => this.onClickBullet(idx) })))))));
    }
}
