import { Dimensions } from "../styling/Dimensions";
export const scrollUtils = new (class StringUtils {
    smoothScroll(top) {
        const scrollTo = top - Dimensions.navBarHeight;
        if (this.supportsSmoothScrolling() === true) {
            console.log("scrollto", scrollTo);
            window.scrollTo({ top: scrollTo, left: 0, behavior: "smooth" });
        }
        else {
            let currentY = window.pageYOffset;
            if (currentY < scrollTo) {
                var int = setInterval(function () {
                    if (currentY > scrollTo - 20)
                        currentY += 1;
                    else if (currentY > scrollTo - 40)
                        currentY += 3;
                    else if (currentY > scrollTo - 80)
                        currentY += 8;
                    else if (currentY > scrollTo - 160)
                        currentY += 18;
                    else if (currentY > scrollTo - 200)
                        currentY += 24;
                    else if (currentY > scrollTo - 300)
                        currentY += 40;
                    else
                        currentY += 60;
                    window.scroll(0, currentY);
                    if (currentY >= scrollTo)
                        clearInterval(int);
                }, 15);
            }
            else {
                var int = setInterval(function () {
                    if (currentY < scrollTo + 20)
                        currentY -= 1;
                    else if (currentY < scrollTo + 40)
                        currentY -= 3;
                    else if (currentY < scrollTo + 80)
                        currentY -= 8;
                    else if (currentY < scrollTo + 160)
                        currentY -= 18;
                    else if (currentY < scrollTo + 200)
                        currentY -= 24;
                    else if (currentY < scrollTo + 300)
                        currentY -= 40;
                    else
                        currentY -= 60;
                    window.scroll(0, currentY);
                    if (currentY <= scrollTo)
                        clearInterval(int);
                }, 15);
            }
        }
    }
    supportsSmoothScrolling() {
        const body = document.body;
        const scrollSave = body.style.scrollBehavior;
        body.style.scrollBehavior = "smooth";
        const hasSmooth = getComputedStyle(body).scrollBehavior === "smooth";
        body.style.scrollBehavior = scrollSave;
        return hasSmooth;
    }
})();
