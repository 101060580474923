import * as React from "react";
import { style } from "typestyle";
import { Dimensions } from "../styling/Dimensions";
export default class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.classNames = {
            notificationWrapper: style({
                position: "fixed",
                bottom: -200,
                left: 0,
                right: 0,
                zIndex: 200,
                width: "100vw",
                justifyContent: "center",
                transition: "0.5s",
                color: "white",
                fontWeight: "bold"
            }),
            notificationShow: style({
                top: 20
            }),
            notification: style({
                maxWidth: 750,
                width: "75vw",
                margin: "0 auto",
                backgroundColor: "rgba(0,0,0,0.9)",
                boxShadow: "0 0 5px 1px black"
            }),
            notificationTitle: style({
                display: "flex",
                fontSize: "1.125rem",
                justifyContent: "center",
                padding: 8,
                width: "100%"
            }),
            notificationContent: style({
                padding: Dimensions.padding * 2,
                width: "100%",
                textAlign: "center"
            })
        };
        this.state = {
            notifyTitle: "",
            notifyText: "",
            show: false
        };
        Notification.instance = this;
    }
    static show(settings) {
        const _this = Notification.instance;
        const transitionTime = 200;
        _this.setState({
            notifyTitle: settings.title,
            notifyText: settings.text,
            show: true
        });
        _this.notifyElement.style.display = "flex";
        setTimeout(() => {
            _this.hide();
        }, settings.hideAfterSeconds
            ? settings.hideAfterSeconds * 1000 + transitionTime
            : 2000);
    }
    static showCreateSucces() {
        Notification.show({
            title: "Succes",
            text: "Succesvol aangemaakt!",
            type: "info",
            hideAfterSeconds: 2
        });
    }
    static showEditSucces() {
        Notification.show({
            title: "Succes",
            text: "Succesvol bewerkt!",
            type: "info",
            hideAfterSeconds: 2
        });
    }
    static showGenericError() {
        Notification.show({
            title: "Er is iets misgegaan",
            text: "Probeer het later nog eens",
            type: "error",
            hideAfterSeconds: 2
        });
    }
    hide() {
        this.setState({ show: false });
    }
    render() {
        return (React.createElement("div", { className: this.classNames.notificationWrapper, ref: (ref) => (this.notifyElement = ref), onClick: () => this.hide(), style: this.state.show ? { bottom: 20 } : {} },
            React.createElement("div", { className: this.classNames.notification },
                React.createElement("div", { className: this.classNames.notificationTitle }, this.state.notifyTitle),
                React.createElement("div", { className: this.classNames.notificationContent }, this.state.notifyText))));
    }
}
