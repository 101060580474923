import React, { useRef } from "react";
import { style } from "typestyle";
import { Colors } from "../../../styling/Colors";
import { Dimensions } from "../../../styling/Dimensions";
import { ElementSlider } from "../elementSlider";
const classNames = {
    desktopWrapper: style({
        $debugName: "desktop-wrapper",
        display: "block",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "none"
        }
    }),
    mobileWrapper: style({
        $debugName: "mobile-wrapper",
        display: "none",
        [`@media screen and (max-width: ${Dimensions.mobileUIWidth}px)`]: {
            display: "block"
        }
    }),
    howWeWorkWrapper: style({
        backgroundColor: Colors.lightGreen
    }),
    howWeWorkHeader: style({
        fontSize: "2rem",
        fontWeight: "bold",
        textAlign: "center",
        color: "white",
        marginBottom: Dimensions.padding * 4
    }),
    iconsWrapper: style({
        maxWidth: Dimensions.maxUIWidth,
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        justifyContent: "space-evenly",
        $nest: {
            [`@media screen and (max-width: ${Dimensions.maxUIWidth}px)`]: {
                flexFlow: "wrap"
            }
        }
    }),
    iconInnerContainer: style({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "white",
        fontWeight: 300,
        padding: Dimensions.padding,
        textAlign: "center"
    }),
    icon: style({
        width: 140,
        height: 140,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        marginBottom: Dimensions.padding
    })
};
export const HowWeWork = (_props) => {
    const howWeWorkIcons = useRef([
        {
            url: "/svgs/how-we-work/how-we-work-1.svg",
            text: "in participatie met de sleutelspelers op zoek naar de échte vragen"
        },
        {
            url: "/svgs/how-we-work/how-we-work-2.svg",
            text: "ontstaan nieuwe oplossingsrichtingen"
        },
        {
            url: "/svgs/how-we-work/how-we-work-3.svg",
            text: "krijgt de kracht van samenwerken ruimte"
        },
        {
            url: "/svgs/how-we-work/how-we-work-4.svg",
            text: "met heldere gemeenschappelijke en individuele doelen"
        },
        {
            url: "/svgs/how-we-work/how-we-work-5.svg",
            text: "voor duurzame oplossingen"
        },
        {
            url: "/svgs/how-we-work/how-we-work-6.svg",
            text: "die boeien, binden en leiden tot actie"
        }
    ]);
    const mobileSliderElements = useRef(howWeWorkIcons.current.map((icon, idx) => {
        return (React.createElement("div", { className: classNames.iconInnerContainer, key: idx },
            React.createElement("div", { className: classNames.icon, key: idx, style: { backgroundImage: `url(${icon.url})` } }),
            React.createElement("div", { style: { color: "white" } }, icon.text)));
    }));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames.desktopWrapper },
            React.createElement("div", { className: classNames.howWeWorkHeader }, "Hoe we werken"),
            React.createElement("div", { className: classNames.iconsWrapper }, howWeWorkIcons.current.map((icon, idx) => {
                return (React.createElement("div", { className: classNames.iconInnerContainer, key: idx },
                    React.createElement("div", { className: classNames.icon, key: idx, style: { backgroundImage: `url(${icon.url})` } }),
                    React.createElement("div", { style: { color: "white" } }, icon.text)));
            }))),
        React.createElement("div", { className: classNames.mobileWrapper },
            React.createElement(ElementSlider, { navigationColor: "white", elementHeight: 250, elements: mobileSliderElements.current }))));
};
