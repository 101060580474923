import React, { useRef, useState } from "react";
import { style } from "typestyle";
import { Dimensions } from "../../styling/Dimensions";
const classNames = {
    container: style({
        $debugName: "expandable-container",
        display: "flex",
        flexDirection: "column",
        borderTop: "1px solid black"
    }),
    borderBottom: style({
        borderBottom: "1px solid black"
    }),
    noBorder: style({
        $debugName: "no-border",
        borderStyle: "none"
    }),
    titleContainer: style({
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        userSelect: "none"
    }),
    title: style({
        cursor: "pointer",
        padding: `${Dimensions.padding}px 0`,
        fontWeight: "bold"
    }),
    arrow: style({
        marginRight: 5
    }),
    arrowDown: style({
        transform: `rotate(90deg)`
    }),
    content: style({
        marginBottom: Dimensions.padding
    })
};
export const ExpandableArea = (props) => {
    var _a;
    const [isExpanded, setIsExpanded] = useState((_a = props.isDefaultExpanded === true) !== null && _a !== void 0 ? _a : false);
    const toggle = useRef((isExpanded) => {
        setIsExpanded(!isExpanded);
    });
    return (React.createElement("div", { className: [
            classNames.container,
            props.isLast === true ? classNames.borderBottom : "",
            isExpanded === true ? classNames.noBorder : ""
        ].join(" "), onClick: toggle.current.bind(this, isExpanded) },
        React.createElement("div", { className: classNames.titleContainer },
            React.createElement("div", { className: [
                    classNames.arrow,
                    isExpanded === true ? classNames.arrowDown : ""
                ].join(" ") }, ">"),
            React.createElement("div", { className: classNames.title }, props.title)),
        isExpanded === true && (React.createElement("div", { className: classNames.content }, props.children))));
};
